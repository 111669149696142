document.addEventListener("DOMContentLoaded", function () {
  var analyzeButton = document.getElementById("analyzeButton");
  if (analyzeButton) {
    analyzeButton.addEventListener("click", function () {
      var textInput = document.getElementById("textInput");
      var wordCountElem = document.getElementById("wordCount");
      var charCountElem = document.getElementById("charCount");
      var wordFreqContainer = document.getElementById("wordFrequency");

      // Check if all elements exist
      if (textInput && wordCountElem && charCountElem && wordFreqContainer) {
        var text = textInput.value;

        // Count characters
        var characterCount = text.length;

        // Count words
        var words = text.match(/\b[-?(\w+)?]+\b/gi);
        var wordCount = words ? words.length : 0;

        // Analyze word contribution
        var wordFrequency = {};
        words.forEach(function (word) {
          word = word.toLowerCase();
          if (!wordFrequency[word]) {
            wordFrequency[word] = 0;
          }
          wordFrequency[word]++;
        });

        // Update UI with results
        wordCountElem.innerText = "Words: " + wordCount;
        charCountElem.innerText = "Characters: " + characterCount;

        // Update the word frequency distribution
        wordFreqContainer.innerHTML = ""; // Clear previous results
        for (var word in wordFrequency) {
          var p = document.createElement("p");
          p.innerText = `${word}: ${wordFrequency[word]} time(s)`;
          wordFreqContainer.appendChild(p);
        }
      }
    });
  }
});
