document.addEventListener("DOMContentLoaded", function () {
  const loremForm = document.getElementById("loremForm");
  const copyButton = document.getElementById("copyButton");
  const generateType = document.getElementById("generateType");
  const amount = document.getElementById("amount");
  const loremText = document.getElementById("loremText");

  if (loremForm && copyButton && generateType && amount && loremText) {
    loremForm.addEventListener("submit", generateLoremIpsum);
    copyButton.addEventListener("click", copyToClipboard);
  }
});

const loremSentences = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
];

function generateLoremIpsum(event) {
  event.preventDefault();
  const type = document.getElementById("generateType").value;
  const amount = parseInt(document.getElementById("amount").value, 10);
  let result = "";

  switch (type) {
    case "paragraphs":
      for (let i = 0; i < amount; i++) {
        const paragraph = generateParagraph();
        result += `<p>${paragraph}</p>`;
      }
      break;
    case "sentences":
      for (let i = 0; i < amount; i++) {
        result += `<p>${generateSentence()}</p>`;
      }
      break;
    case "words":
      result += `<p>${generateWords(amount)}</p>`;
      break;
  }

  document.getElementById("loremText").innerHTML = result;
}

function generateParagraph() {
  let paragraph = "";
  const numSentences = getRandomInt(4, 8);
  for (let j = 0; j < numSentences; j++) {
    paragraph += generateSentence() + " ";
  }
  return paragraph.trim();
}

function generateSentence() {
  return loremSentences[getRandomInt(0, loremSentences.length - 1)];
}

function generateWords(numWords) {
  const words = [];
  for (let i = 0; i < numWords; i++) {
    const sentence = generateSentence();
    const splitWords = sentence.split(" ");
    words.push(splitWords[getRandomInt(0, splitWords.length - 1)]);
  }
  return words.join(" ");
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function copyToClipboard() {
  const loremText = document.getElementById("loremText").innerText;
  navigator.clipboard
    .writeText(loremText)
    .then(() => {
      alert("Text copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err);
    });
}
