import prettify from "@liquify/prettify";

document.addEventListener("DOMContentLoaded", function () {
  const outputElement = document.getElementById("output");
  const prettifyButton = document.getElementById("prettifyButton");
  const htmlInput = document.getElementById("htmlInput");
  const indentSizeInput = document.getElementById("indentSize");
  const formatTypeInput = document.getElementById("formatType");
  const copyButton = document.getElementById("copyButton");

  if (
    outputElement &&
    prettifyButton &&
    htmlInput &&
    indentSizeInput &&
    formatTypeInput &&
    copyButton
  ) {
    // All elements exist
    prettifyButton.addEventListener("click", function () {
      const codeInput = htmlInput.value;
      const indentSize = parseInt(indentSizeInput.value, 10);
      const formatType = formatTypeInput.value;

      prettify
        .format(codeInput, {
          language: formatType,
          indentSize: indentSize,
        })
        .then((formattedCode) => {
          outputElement.textContent = formattedCode;
        })
        .catch((error) => {
          console.error(
            `Error prettifying ${formatType.toUpperCase()}:`,
            error
          );
          outputElement.textContent = codeInput;
        });
    });

    copyButton.addEventListener("click", function () {
      if (outputElement.textContent) {
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(outputElement.textContent)
            .then(() => alert("Content copied to clipboard!"))
            .catch((err) => console.error("Failed to copy text: ", err));
        } else {
          console.error("Clipboard API not available");
        }
      } else {
        alert("No content to copy.");
      }
    });
  }
});
