document.addEventListener("DOMContentLoaded", function () {
  const colorPicker = document.getElementById("colorPicker");
  const hexColor = document.getElementById("hexColor");
  const colorInfo = document.getElementById("colorInfo");

  if (colorPicker && hexColor && colorInfo) {
    // All elements exist
    colorPicker.addEventListener("input", syncColor);
    hexColor.addEventListener("input", syncColor);
  }
});

function syncColor(event) {
  const color = event.target.value;
  const colorPicker = document.getElementById("colorPicker");
  const hexColor = document.getElementById("hexColor");

  if (colorPicker && hexColor) {
    colorPicker.value = color;
    hexColor.value = color;
    updateColorInfo(color);
  } else {
    console.error("One or more elements do not exist.");
  }
}

function updateColorInfo(hex) {
  const colorInfo = document.getElementById("colorInfo");
  if (colorInfo) {
    const rgb = hexToRgb(hex);
    const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
    const hsv = rgbToHsv(rgb.r, rgb.g, rgb.b);
    const hwb = rgbToHwb(rgb.r, rgb.g, rgb.b);
    const cmyk = rgbToCmyk(rgb.r, rgb.g, rgb.b);

    const output = `
            <p>HEX: ${hex.toUpperCase()}</p>
            <p>RGB: ${rgb.r}, ${rgb.g}, ${rgb.b}</p>
            <p>HSL: ${hsl.h}, ${hsl.s}%, ${hsl.l}%</p>
            <p>HSV: ${hsv.h}, ${hsv.s}%, ${hsv.v}%</p>
            <p>HWB: ${hwb.h}, ${hwb.w}%, ${hwb.b}%</p>
            <p>CMYK: ${cmyk.c}%, ${cmyk.m}%, ${cmyk.y}%, ${cmyk.k}%</p>
        `;

    colorInfo.innerHTML = output;
  } else {
    console.error("Color info element does not exist.");
  }
}

function hexToRgb(hex) {
  hex = hex.replace(/^\s*#|\s*$/g, "");
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map(function (hexPart) {
        return hexPart + hexPart;
      })
      .join("");
  }
  var r = parseInt(hex.substr(0, 2), 16);
  var g = parseInt(hex.substr(2, 2), 16);
  var b = parseInt(hex.substr(4, 2), 16);
  return { r: r, g: g, b: b };
}

function rgbToHsl(r, g, b) {
  (r /= 255), (g /= 255), (b /= 255);
  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h;
  var s;
  var l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
}

function rgbToHsv(r, g, b) {
  (r /= 255), (g /= 255), (b /= 255);
  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h;
  var s;
  var v = max;

  var d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    v: Math.round(v * 100),
  };
}

function rgbToHwb(r, g, b) {
  var h = rgbToHsl(r, g, b).h; // Reuse h from HSL
  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var w = min / 255;
  var b = 1 - max / 255;
  return { h: h, w: Math.round(w * 100), b: Math.round(b * 100) };
}

function rgbToCmyk(r, g, b) {
  if (r == 0 && g == 0 && b == 0) {
    return { c: 0, m: 0, y: 0, k: 100 };
  }
  var c = 1 - r / 255;
  var m = 1 - g / 255;
  var y = 1 - b / 255;
  var k = Math.min(c, Math.min(m, y));
  c = (c - k) / (1 - k);
  m = (m - k) / (1 - k);
  y = (y - k) / (1 - k);
  return {
    c: Math.round(c * 100),
    m: Math.round(m * 100),
    y: Math.round(y * 100),
    k: Math.round(k * 100),
  };
}
