function detectScreenResolution() {
  // Get the screen width and height using the screen object
  var screenWidth = screen.width;
  var screenHeight = screen.height;

  // Display the screen resolution
  document.getElementById("resolution").innerText =
    "Your screen resolution is: " + screenWidth + "x" + screenHeight;
}

// Run the function when the page loads
window.onload = detectScreenResolution;
