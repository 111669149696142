document.addEventListener("DOMContentLoaded", function () {
  const textarea = document.getElementById("numberedTextarea");
  const sortButton = document.getElementById("sortButton");

  if (!textarea || !sortButton) {
    return;
  }

  textarea.addEventListener("keydown", handleTextareaInput);
  textarea.addEventListener("paste", handlePaste);
  sortButton.addEventListener("click", sortLines);

  // Initialize with the first line numbered
  textarea.value = "1. ";
  let lineNumber = 1;

  function handleTextareaInput(e) {
    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      lineNumber = textarea.value.split("\n").length + 1;
      textarea.value += `\n${lineNumber}. `;
    } else if (e.keyCode === 8) {
      // Backspace key
      setTimeout(() => {
        const cursorPos = textarea.selectionStart;
        const lines = textarea.value.substring(0, cursorPos).split("\n");

        if (lines[lines.length - 1].trim() === `${lines.length}.`) {
          e.preventDefault();
          lines.pop(); // Remove the empty line
          lineNumber = lines.length;
          textarea.value =
            lines.join("\n") + textarea.value.substring(cursorPos);
          if (lines.length === 0) {
            textarea.value = "1. "; // Reset to first line if all lines are deleted
            lineNumber = 1;
          }
        }
      }, 0);
    }
  }

  function handlePaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");

    const lines = pastedData.split(/\r?\n/);
    const existingLines = textarea.value.split("\n");

    if (existingLines.length === 1 && existingLines[0].trim() === "1.") {
      textarea.value = "";
    }

    const currentLineNumber =
      existingLines.length === 0 ? 1 : existingLines.length;

    const formattedLines = lines.map((line, index) => {
      return `${currentLineNumber + index}. ${line.trim()}`;
    });

    textarea.value += formattedLines.join("\n");
    lineNumber = currentLineNumber + lines.length - 1;
  }

  function sortLines() {
    const sortOrder = document.getElementById("sortOrder").value;
    let lines = textarea.value.split("\n");

    lines = lines.map((line) => line.substring(line.indexOf(" ") + 1));
    lines.sort();

    if (sortOrder === "descending") {
      lines.reverse();
    }

    lines = lines.map((line, index) => `${index + 1}. ${line}`);
    textarea.value = lines.join("\n");
  }
});
