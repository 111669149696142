import DiffMatchPatch from "diff-match-patch";

const dmp = new DiffMatchPatch();

function performDiff() {
  const text1Element = document.getElementById("text1");
  const text2Element = document.getElementById("text2");
  const originalElement = document.getElementById("original");
  const modifiedElement = document.getElementById("modified");

  if (text1Element && text2Element && originalElement && modifiedElement) {
    const text1 = text1Element.value;
    const text2 = text2Element.value;

    const diffs = dmp.diff_main(text1, text2);
    dmp.diff_cleanupSemantic(diffs);

    // Split the diffs into two parts: original and modified
    const originalHtml = [];
    const modifiedHtml = [];
    diffs.forEach(([operation, text]) => {
      switch (operation) {
        case -1: // Deletion
          originalHtml.push(
            `<span class="deletion">${escapeHtml(text)}</span>`
          );
          break;
        case 1: // Insertion
          modifiedHtml.push(
            `<span class="insertion">${escapeHtml(text)}</span>`
          );
          break;
        case 0: // No change
          originalHtml.push(escapeHtml(text));
          modifiedHtml.push(escapeHtml(text));
          break;
      }
    });

    originalElement.innerHTML = originalHtml.join("");
    modifiedElement.innerHTML = modifiedHtml.join("");
  } else {
    console.error("One or more elements do not exist.");
  }
}

function escapeHtml(text) {
  return text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

document.addEventListener("DOMContentLoaded", function () {
  const diffButton = document.getElementById("diffButton");
  if (diffButton) {
    diffButton.addEventListener("click", performDiff);
  }
});
